import moment from 'moment'

export function FlatPickrToTimestamp(date) {
  const parts = date.split("/");
  const dateIni = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`);
  return dateIni.getTime() / 1000;
}

export function TimestampToFlatPickr(timestamp) {
  const dateIni = new Date(timestamp * 1000);
  let output = "";
  if (dateIni && timestamp) {
    output = `${dateIni.getDate()}/${
      dateIni.getMonth() + 1
    }/${dateIni.getFullYear()}`;
  }
  return output;
}

// Formatear en dd/mm/yyyy
export function ISOToCustomFormat(date) {
  const dateObj = new Date(date);
  
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();
  
  return `${day}/${month}/${year}`;
}

// Formatear en dd/mm/yyyy hh:mm
export function TimestampToCustomFormat(timestamp, offset = '-06:00') {
  const dateObj = moment.unix(timestamp).utcOffset(offset);

  // Formatear en ISO 8601 con el desplazamiento de zona horaria
  return dateObj.format('YYYY-MM-DDTHH:mm:ssZ');
}

export function FlatPickrToTimestampWithTime(date) {
  const parts = date.split("/");
  const dateIni = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`);
  console.log(dateIni);
  return dateIni.getTime() / 1000;
}

export function TimestampToFlatPickrWithTime(timestamp) {
  const dateIni = new Date(timestamp * 1000);
  let output = "";
  var min = dateIni.getMinutes().toString();
  if (min.length == 1) {
    min = "0" + min;
  }
  if (dateIni && timestamp) {
    output = `${dateIni.getDate()}/${
      dateIni.getMonth() + 1
    }/${dateIni.getFullYear()} ${dateIni.getHours()}:${min}`;
  }
  return output;
}
